class HomeSearchForm extends React.Component {
  constructor(props) {
    super(props);
    let div = document.getElementById('home_search_form');
    // let csrf_token = div.getAttribute('csrf_token')
    // this.state = {
    //   csrf_token: csrf_token,
    // }
  }

  render() {
    let div = document.getElementById('home_search_form');
    // let csrf_token = div.getAttribute('csrf_token')
    // <form action="/search/" method="GET">
    //   <div dangerouslySetInnerHTML={{__html: csrf_token }} />
    return (
      <div>
        <form action="/search/" method="GET">
          <div className="row">
            <div className="large-8 large-offset-2 columns">
              <div className="input-group">
                <span className="input-group-label">Search Now</span>
                <input
                  className="input-group-field"
                  type="search"
                  name="query"
                  placeholder="title, author, series, isbn"
                />
                <div className="input-group-button">
                  <input type="submit" className="button" value="Go!" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ReactDOM.render(
  <HomeSearchForm />,
  document.getElementById('home_search_form'),
);

// eof
